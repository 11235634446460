import { IFELHASZNALO_DISP } from '@coimbra-its/api.konyvelo';
import { produce } from 'immer';
import { create } from 'zustand';


export interface IAuthState {
	sessionId: string | undefined,
	user: IFELHASZNALO_DISP | undefined,
	showSignOut: boolean;
}


export type IAuthSlice = IAuthState & {
	signedIn: (sessionId: string, user: IFELHASZNALO_DISP) => void;
	startupReady: (user?: IFELHASZNALO_DISP) => void;
	signedOut: () => void;

	setShowSignOut: (v: boolean) => void;
}


export const useWSysAuthSlice = create<IAuthSlice>()(


	(set, get) => ({
		sessionId: localStorage.getItem('SESSION_ID') || undefined,
		user: undefined,
		showSignOut: false,

		// --- after get sessionId in url query ----

		signedIn: (sessionId: string, user: IFELHASZNALO_DISP) => set(produce((state: IAuthSlice) => {
			state.user = user;
			window.localStorage.setItem('SESSION_ID', sessionId);
		})),

		startupReady: (user?: IFELHASZNALO_DISP) => set(produce((state: IAuthSlice) => {
			state.user = user;
		})),

		signedOut: () => set(produce((state: IAuthSlice) => {
			state.user = undefined;
			state.sessionId = undefined;
			window.localStorage.removeItem('SESSION_ID');
		})),

		setShowSignOut: (v: boolean) => set(produce((state: IAuthSlice) => {
			state.showSignOut = v;
		})),

	}));

export function useSessionId() {
	//const { sessionId } = useWSysAuthSlice((state: IAuthSlice) => ({ sessionId: state.sessionId }));
	return window.localStorage.getItem('SESSION_ID');
}





