import { Icon, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { IMUNKASZAM } from "@coimbra-its/api.konyvelo";
import { CellProps, useDebounce, useWSysLocalFilter, useWSysPage, useWSysPath, WSysPage, WSysSearch, WSysTable, WSysVert } from "@coimbra-its/websys-ux-core";
import { useEffect, useState } from "react";
import { APPICONS } from "../Icons";


export const CELLS: CellProps<IMUNKASZAM>[] = [
    { 'title': 'Megnevezés', elem: (row) => row.TARGY, charWidth: 30 },
];

export function MunkaszamList({ munkaszamok, onSelect, onClosed }: { munkaszamok: IMUNKASZAM[], onSelect: (mszam: IMUNKASZAM) => void, onClosed:()=>void }) {

    useWSysPath();
    /*const [q] = path.useParam('q');*/
    const page = useWSysPage({ title: 'További kategóriák', maxCols: 2, variant: 'popup', onClosed });

    const [q, setQ] = useState('');
    const debouncedQ = useDebounce(q, 1);
    const [filteredMunkaszamok, setFilteredMunkaszamok] = useState<IMUNKASZAM[]>(munkaszamok);
    useEffect(() => {
        if (!debouncedQ) {
            setFilteredMunkaszamok(munkaszamok)
        } else {
            setFilteredMunkaszamok(
                munkaszamok
                    .filter(msz => msz.TARGY.toLocaleUpperCase().indexOf(debouncedQ.toLocaleUpperCase()) !== -1)
                    .sort(
                        (a, b) =>
                            (b.TARGY.toLocaleUpperCase().startsWith(debouncedQ.toLocaleUpperCase()) ? 10 : 0)
                            - (a.TARGY.toLocaleUpperCase().startsWith(debouncedQ.toLocaleUpperCase()) ? 10 : 0)
                            + a.TARGY.localeCompare(b.TARGY)
                    )
            )
        }
    }, [debouncedQ, munkaszamok]);



    return <WSysPage page={page}
        tools={
            <InputGroup>
                <InputLeftElement
                    pointerEvents='none'
                    children={<Icon color='gray.300' as={APPICONS.Search} />}
                />
                <Input size='sm' value={q} onChange={e => setQ(e.currentTarget.value)} />
            </InputGroup>
        }
    >
        <WSysVert mode="rest">
            <WSysTable
                rows={filteredMunkaszamok}
                cells={CELLS}
                onRowSelect={onSelect}
                noHead={true}
            >

            </WSysTable>
        </WSysVert>
    </WSysPage>
}