import { Box, Button, Icon } from "@chakra-ui/react";
import { exctractErrorMessage, IWSysHub, useWSysHub, WSysHeaderButton } from "@coimbra-its/websys-ux-core";
import { useContext, useRef, useState } from "react";
import { useSessionId } from "../store/authSlice";
import { APPICONS } from "../Icons";
import { KonyveloApi, useKonyveloApi } from "../store/konyvelo.api";





export function SzamlaCsatolasUj({ hub, DOKID }: { hub: IWSysHub, DOKID: number }) {

    const inputFileRef = useRef(null);
    const sessionId = useSessionId();
    const fullApi = useContext(KonyveloApi);


    const uploadClick = () => {
        if (inputFileRef.current) (inputFileRef.current as any).click();
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0 || !sessionId)
            return;

        hub.tryCatch(async () => {

            const file = e.target.files![0];
            const url = process.env.REACT_APP_API_BASE_URL + `csatolas/${DOKID}?wss-token=${encodeURIComponent(sessionId)}&origFile=${encodeURIComponent(file.name)}`;


            const formData = new FormData();
            formData.append("file", file);
            const resp = await fetch(url, {
                method: "POST",
                body: formData,
            });
            /*await csatolasPost({
                origFile: file.name,
                DOKID: DOKID
            }, {
                body: formData,
                init: {
                    headers: { 'Content-Type': `multipart/form-data; boundary=----` },
                }
            });*/
            e.target.value = '';
            const data = await resp.json();
            if (!resp.ok) {
                if (data && data.publicMessage)
                    throw new Error(data.publicMessage)
                else
                    throw new Error('Feltöltési hiba!')
            }
            fullApi.invalidate(['szamla']);


        });

    };

    return <>
        <Box display='none'>
            <input ref={inputFileRef} id="file" type="file" accept="application/pdf, image/jpeg" onChange={handleFileChange} />
        </Box>
        {/*<WSysHeaderButton colorScheme='primary' onClick={() => uploadClick()} icon={<APPICONS.Photo />}>Számlakép feltöltése</WSysHeaderButton>*/}
        <Button colorScheme='primary' onClick={() => uploadClick()} ><Icon as={APPICONS.Photo} />&nbsp;Számlakép feltöltése</Button>


    </>
}