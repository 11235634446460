import { Box, Button, Collapse, FormControl, FormLabel, Icon, IconButton, Input, InputGroup, InputLeftAddon, InputLeftElement, InputRightAddon, InputRightElement, Stack, Switch, Text, Tooltip, useBoolean } from "@chakra-ui/react";
import { IOSZAMLA_BESZLA_FEJ, ISZAMLA_FORM } from "@coimbra-its/api.konyvelo"
import { formatDate, formatNumber, useWSysPage, WSysGrid, WSysPage, WSysVert } from "@coimbra-its/websys-ux-core"
import { APPICONS } from "../Icons";



export function SzamlaDetails({ szamla, szamlaInfo }: { szamla: IOSZAMLA_BESZLA_FEJ, szamlaInfo: ISZAMLA_FORM }) {

    const page = useWSysPage({
        title: 'Részletek', maxCols: 2, variant: 'attach'
        , wsStyle: 'inverted'
    });

    return <WSysPage page={page} borderLeft='2px solid #2c6195'>
        <WSysVert mode="static" isInverted={true}>
            <Stack p={2}>
                <FormControl>
                    <FormLabel>Szállító neve</FormLabel>
                    <InputGroup>
                        <InputLeftAddon><Icon opacity='.5' as={APPICONS.User} />&nbsp;{szamlaInfo.P_KOD || ''}&nbsp;</InputLeftAddon>
                        <Input value={szamla.SUPPLIERNAME || ''} onChange={() => { }} readOnly={true} />
                    </InputGroup>
                </FormControl>

                <FormControl>
                    <FormLabel>Számlaszám</FormLabel>
                    <Input value={szamla.INVOICENUMBER || ''} onChange={() => { }} readOnly={true} />
                </FormControl>

            </Stack>
        </WSysVert>

        <WSysVert mode="rest" isInverted={false}>
            <Stack p={2}>
                <FormControl>
                    <FormLabel>Számla dátuma</FormLabel>
                    <Input value={formatDate(szamla.INVOICEISSUEDATE)} onChange={() => { }} readOnly={true} />
                </FormControl>

                <FormControl>
                    <FormLabel>Teljesítés dátuma</FormLabel>
                    <Input value={formatDate(szamla.INVOICEDELIVERYDATE)} onChange={() => { }} readOnly={true} />
                </FormControl>

                <FormControl>
                    <FormLabel>Fizetési határidő</FormLabel>
                    <Input value={formatDate(szamla.PAYMENTDATE)} onChange={() => { }} readOnly={true} />
                </FormControl>

                <FormControl>
                    <FormLabel>Fizetési mód</FormLabel>
                    <Input value={szamlaInfo.FIZMOD || ''} onChange={() => { }} readOnly={true} />
                </FormControl>
                <FormControl>
                    <FormLabel>Nettó</FormLabel>
                    <InputGroup>
                        <Input value={formatNumber(szamla.INVOICENETAMOUNT)} onChange={() => { }} readOnly={true} textAlign='right' />
                        <InputRightAddon>&nbsp;{(szamla.CURRENCY === 'HUF' ? 'Ft' : szamla.CURRENCY)}</InputRightAddon>
                    </InputGroup>
                </FormControl>
                <FormControl>
                    <FormLabel>ÁFA</FormLabel>
                    <InputGroup>
                        <Input value={formatNumber(szamla.INVOICEVATAMOUNT)} onChange={() => { }} readOnly={true} textAlign='right' />
                        <InputRightAddon>&nbsp;{(szamla.CURRENCY === 'HUF' ? 'Ft' : szamla.CURRENCY)}</InputRightAddon>
                    </InputGroup>
                </FormControl>
                <FormControl>
                    <FormLabel>Brutto</FormLabel>
                    <InputGroup>
                        <Input value={formatNumber(szamla.INVOICENETAMOUNT + szamla.INVOICEVATAMOUNT)} onChange={() => { }} readOnly={true} textAlign='right' />
                        <InputRightAddon>&nbsp;{(szamla.CURRENCY === 'HUF' ? 'Ft' : szamla.CURRENCY)}</InputRightAddon>
                    </InputGroup>
                </FormControl>
                {szamla.CURRENCY !== 'HUF' && <>
                    <FormControl>
                        <FormLabel>Nettó</FormLabel>
                        <InputGroup>
                            <Input value={formatNumber(szamla.INVOICENETAMOUNTHUF)} onChange={() => { }} readOnly={true} textAlign='right' />
                            <InputRightAddon>&nbsp;Ft</InputRightAddon>
                        </InputGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel>ÁFA</FormLabel>
                        <InputGroup>
                            <Input value={formatNumber(szamla.INVOICEVATAMOUNTHUF)} onChange={() => { }} readOnly={true} textAlign='right' />
                            <InputRightAddon>&nbsp;Ft</InputRightAddon>
                        </InputGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Brutto</FormLabel>
                        <InputGroup>
                            <Input value={formatNumber(szamla.INVOICENETAMOUNTHUF + szamla.INVOICEVATAMOUNTHUF)} onChange={() => { }} readOnly={true} textAlign='right' />
                            <InputRightAddon>&nbsp;Ft</InputRightAddon>
                        </InputGroup>
                    </FormControl>
                </>}
            </Stack>
        </WSysVert>
    </WSysPage >
}