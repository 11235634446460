import { Input, InputProps } from "@chakra-ui/react";
import { useDebounce, WSysInputBaseProps } from "@coimbra-its/websys-ux-core";
import { useEffect, useState } from "react";


const INPUT_DEBOUNCE_MS = 600;
export interface NumberInputProps extends InputProps, WSysInputBaseProps<any, number> {}

export function NumberInput({ readOnly = false, onChange: pOnChange, value: pValue, onDebouncedChange
	, ...rest
}: NumberInputProps) {
	const [oldValue, setOldValue] = useState(pValue);
	useEffect(() => {
		setChangedValue(undefined);
		setOldValue(pValue);
	}, [pValue]);

	const [inputValue, setInputValue] = useState('');
	const [changedValue, setChangedValue] = useState<number | undefined>(undefined); // undefined = input is untouched
	const debouncedChangedValue = useDebounce(changedValue, INPUT_DEBOUNCE_MS);

	const [isFocused, setIsFocused] = useState(false);

	const addThousandSeparator = (num: any) => (num || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

	const _setInputValue = () => {
		if (readOnly || changedValue === undefined) {
			console.log('BEÁLLÍTOM: ', '' + (isFocused ? pValue : addThousandSeparator(pValue)))
			setInputValue('' + (isFocused ? pValue : addThousandSeparator(pValue)));
		}
		if (readOnly)
			setChangedValue(undefined);
	}

	useEffect(() => {
		_setInputValue();
	}, [readOnly, changedValue, pValue, isFocused]);

	const inputValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		let val = e.currentTarget.value;
		console.log('setChanged', val);
		setChangedValue(parseFloat(val));
		setInputValue(val);
	}

	const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		setIsFocused(true);
		_setInputValue();
		let t = e.currentTarget;
		setTimeout(() => {
			t.select();
		},2);
	}

	useEffect(() => {
		if (!readOnly && debouncedChangedValue !== undefined && onDebouncedChange) {
			if (isNaN(debouncedChangedValue)) {
				onDebouncedChange(0);
			} else {
				onDebouncedChange(debouncedChangedValue);
			}
			
		}
	}, [readOnly, debouncedChangedValue]);

	return <><Input
		type={isFocused && !readOnly ? "text" : "text"} textAlign='right' autoComplete="off"

		readOnly={readOnly} value={inputValue} onChange={inputValueChanged}
		onFocus={e => onFocus(e)} onBlur={() => setIsFocused(false)}
		{...readOnly && { tabIndex: -1 }}
		{...rest}
	/>
	</>
}

//WSysNumber.__sysInput = true;