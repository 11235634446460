import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from "@chakra-ui/react";
import { useWSysHub, WSysHubDisplay } from "@coimbra-its/websys-ux-core";
import { useRef } from "react";
import { useWSysAuthSlice } from "../store/authSlice";
import { useKonyveloApi } from "../store/konyvelo.api";




export function SignOut() {

	const api = useKonyveloApi();
	const hub = useWSysHub();
	
	const authSlice = useWSysAuthSlice();

	const cancelRef = useRef(null)
	const doSignOut = api.signOutPost.useAction();
	const signOutClicked = async () => {
		hub.tryCatch(async () => {
			await doSignOut({});
			authSlice.signedOut();
			authSlice.setShowSignOut(false);
		})
	}
    const signOutCancel = () => {
		authSlice.setShowSignOut(false);
    }

	return <AlertDialog
		isOpen={authSlice.showSignOut}
		leastDestructiveRef={cancelRef}
		onClose={signOutCancel}
	>
		<AlertDialogOverlay>
			<AlertDialogContent>
				<WSysHubDisplay hubs={[hub]}>
					<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						Kijelentkezés
					</AlertDialogHeader>

					<AlertDialogBody>
						Biztosan kijelentkezik
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={signOutCancel}>
							Mégsem
						</Button>
						<Button colorScheme='red' onClick={signOutClicked} ml={3}>
							Kijelentkezés
						</Button>
					</AlertDialogFooter>
				</WSysHubDisplay>
			</AlertDialogContent>
		</AlertDialogOverlay>
	</AlertDialog>

}