import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";


export function WSysServerMessages() {

	const [searchParams, setSearchParams] = useSearchParams();

	const urlServerError = searchParams.get("server-error") || undefined;
	const onClose= () => {
		searchParams.delete('server-error');
		setSearchParams(searchParams);
	}

	return <Modal isOpen={!!urlServerError} onClose={onClose}>
		<ModalOverlay />
		<ModalContent>
			<ModalHeader>Server message</ModalHeader>
			<ModalBody>
				{urlServerError}
			</ModalBody>
			<ModalFooter>
				<Button colorScheme='red' mr={3} onClick={onClose}>
					Ok
				</Button>
			</ModalFooter>

		</ModalContent>
	</Modal>

}