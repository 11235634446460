import { useEffect, useState } from "react";


import { Box, Button, Divider, FormControl, FormLabel, Img, Input, Link, Stack, Text } from "@chakra-ui/react";
import { useWSysHub, useWSysPage, WSysPage } from "@coimbra-its/websys-ux-core";
import { useSearchParams } from "react-router-dom";
import { SunMainPage } from "../components/Sun";
import { useWSysAuthSlice } from "../store/authSlice";
import { useKonyveloApi } from "../store/konyvelo.api";


export default function SignInForm() {
	const [searchParams, setSearchParams] = useSearchParams();
	const pathSponsorHash = searchParams.get("sid") || undefined;

	const storeSessionId = useWSysAuthSlice(state => state.sessionId);



	const api = useKonyveloApi();
	const hub = useWSysHub();

	const [loginName, setLoginName] = useState('');
	const [password, setPassword] = useState('');

	const startupPost = api.startupPost.useAction();
	const startupReady = useWSysAuthSlice(s => s.startupReady);


	useEffect(() => {
		hub.tryCatch(async () => {
			const res = await startupPost({
				valami: 1
			});
			startupReady(res.user);
		});
	}, [storeSessionId, pathSponsorHash])



	const signInPost = api.signInPost.useAction();
	const signedIn = useWSysAuthSlice(state => state.signedIn);
	const doSignIn = () => {
		hub.tryCatch(async () => {
			const res = await signInPost({
				loginName: loginName,
				password
			});
			signedIn(res.SESSION_ID, res.user);
		});
	}

	const page = useWSysPage({ title: 'WebSys Könyvelő', maxCols: 3, onClosed: false });

	// --------------------------------------



	return <SunMainPage page={page} hubs={[hub]}  //headerTitle={sponsor?'Register':'Sign In'}
		headerTitle={' '} bg='transparent'
	>
		<Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>


			<Stack spacing={2} p={2}>
				<Box display='flex' justifyContent='space-evenly' alignItems='center' gap={2}>
					<Img src="/icon-512x512.png" w='180px' />
					<Box pl={2}>
						<Text sx={{ my: '0px' }} fontWeight='bold' fontSize='1.4em'>WebSys Könyvelő</Text>
						<Text sx={{ fontSize: '1em' }}>WebSys Könyvelő</Text>
						<Text color={process.env.REACT_APP_STAGE === 'production' ? '#888' : 'red'}
						>{process.env.REACT_APP_STAGE}</Text>
					</Box>
				</Box>
				<Box my='1em'>
					<form>
						<FormControl>
							<FormLabel>Felhasználó név</FormLabel>
							<Input type='text' value={loginName} onChange={e => setLoginName(e.target.value)} autoComplete="username" />
						</FormControl>
						<FormControl>
							<FormLabel>Jelszó</FormLabel>
							<Input type='password' value={password} onChange={e => setPassword(e.target.value)} autoComplete="current-password" />
						</FormControl>
					</form>
				</Box>
				<Divider />
				<Box my='1em' display='flex' flexDir='row' alignItems='center' justifyContent='space-evenly'>
					<Button type='submit' value='Sign in' onClick={doSignIn} colorScheme="primary" >Sign in</Button>
				</Box>

				<Divider />
				<Box fontSize='.75em' color='#888' display='flex' alignItems='center' flexDir='column'>
					<Link href="https://coimbraits.hu" target="_blank" >WebSys - © 2024 - Coimbra ITS Kft.</Link>
				</Box>
			</Stack>
		</Box>
	</SunMainPage>
}

