import { ICSATOLAS } from "@coimbra-its/api.konyvelo";
import { CellProps, IWSysHubEvent, useWSysHub, useWSysPage, WSysPage, WSysVert } from "@coimbra-its/websys-ux-core";
import { SyntheticEvent, useEffect, useMemo } from "react";
import { useSessionId } from "../store/authSlice";
import { Image } from "@chakra-ui/react";

export const CELLS: CellProps<ICSATOLAS>[] = [
    { 'title': 'Szállító', elem: (row) => row.FILENEV, charWidth: 30 },
];


export function Dok({ csatolas }: { csatolas: ICSATOLAS }) {
    const title = csatolas ? csatolas.FILENEV : 'Válassz csatolást...';
    const page = useWSysPage({ title, variant: 'attach', maxCols: 3, 
        //onClosed: onDocClose 
    });
    const sessionId = useSessionId();

    const file = process.env.REACT_APP_API_BASE_URL + 'csatolas/' + csatolas.ID + '?wss-token=' + sessionId;
    const ext = '.'+ csatolas.FILENEV.split('.').pop()!.toLowerCase();

    const hub = useWSysHub();
    const onError = (e: SyntheticEvent) => {
        hub.addEvent({ message: 'Nem találom a file-t', severity: 'error' });
    }
    const hubLoading = useMemo<IWSysHubEvent>(() => ({ message: 'Loading...', severity: 'loading' }), [])
    useEffect(() => {
        if (!file)
            hub.addEvent(hubLoading);
        else
            hub.removeEvent(hubLoading);
    }, [file])


    return <WSysPage page={page} borderLeft='2px solid #2c6195'
        hubs={[hub]}
    >
        <WSysVert mode="static" width="100%" height='100%' display='flex'>
            {file && ext=='.pdf' && <object key={file} data={file} width="100%" height='100%'  style={{display:'flex', alignItems:'center', justifyContent:'center'}}
                //type="application/pdf"
                //onLoad={() => downloadRemote.reset()}
                onErrorCapture={onError}
            >
                <p>Unable to display file. <a href={file}>Download</a> instead.</p>
            </object>}
            {file && ext!=='.pdf' && <Image src={file} objectFit='contain' />}
        </WSysVert>
    </WSysPage>
}
