
import {
	BsCheck as BsCheck2Square, //TODO
	BsSquare
} from 'react-icons/bs';
import { FaArrowLeft, FaArrowRight, FaCheckCircle, FaExclamationCircle, FaFilter, FaUser } from 'react-icons/fa';
import {
	MdAccountTree,
	MdAddCircleOutline,
	MdArrowBack,
	MdArrowForward,
	MdArrowRight,
	MdArrowRightAlt,
	MdAttachFile,
	MdCancel,
	MdChecklist,
	MdClose,
	MdCloudUpload,
	MdCreditCard,
	MdEdit,
	MdEmail,
	MdExpandCircleDown,
	MdFileOpen, //TODO
	MdHome,
	MdInfo,
	MdInfoOutline,
	MdJoinRight,
	MdLogout,
	MdMenu,
	MdNewspaper,
	MdOpenInNew,
	MdPhone,
	MdPhotoCamera,
	MdPlusOne,
	MdSave,
	MdScheduleSend,
	MdSearch,
	MdSettings,
	MdShare,
	MdShoppingCart,
	MdSubdirectoryArrowLeft,
	MdSubdirectoryArrowRight,
	MdTrendingUp,
	MdWallet
} from 'react-icons/md';
import { RiCloseCircleFill } from 'react-icons/ri';

export const APPICONS = {
	SignOut : MdLogout,
	Close : MdClose,
	Back : MdArrowBack,
	Cancel : MdCancel,
	Prev : MdArrowBack,
	Next : MdArrowForward,
	Menu : MdMenu,
	Save : MdSave,
	Edit : MdEdit,
	Search : MdSearch,
	Filter : FaFilter,
	FilterOff : MdCancel,
	Create : MdAddCircleOutline,
	Delete : RiCloseCircleFill,
	DeleteRow : MdClose,
	Home : MdHome,
	Preferences : MdSettings,
	Alert : FaExclamationCircle,
	Success : FaCheckCircle,
	Left : FaArrowLeft,
	Right : FaArrowRight,
	User : FaUser,
	Attach : MdAttachFile,
	Upload : MdCloudUpload,
	Settings : MdSettings,
	Check : BsCheck2Square,
	CheckOff : BsSquare,

	Phone: MdPhone,
	Email: MdEmail,


	Chart: MdTrendingUp,
	Shop: MdShoppingCart,
	Wallet: MdWallet,
	CreditCard: MdCreditCard,
	Transfer: MdScheduleSend,
	News: MdNewspaper,
	Tree: MdAccountTree,
	Pfd: MdFileOpen,

	NewRow: MdSubdirectoryArrowLeft,
	Photo: MdPhotoCamera,
	Share : MdShare,
	Szamlak : MdChecklist,
	Details : MdInfoOutline,


	/*Partner : FaUser,
	Paper : MdInsertDriveFile,
	Item : FaCircle,
	Raktar : FaWarehouse,
	Task : FaTasks,
	KeszletMozgas : FaTruck,
	Article : FaBox,
	Tasks : FaTasks,*/
}