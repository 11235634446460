import { background, Button, theme, ToastProvider } from '@chakra-ui/react';
import { AppTheme, WSYS_TABLE_SELECTION_STYLE, WSysPath } from '@coimbra-its/websys-ux-core';
import ReactDOM from 'react-dom/client';
import App from './App';
import { APPICONS } from './Icons';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import { mode } from '@chakra-ui/theme-tools'


const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const preferencesCompanyDefault_PROD = `{"darkMode":false,"baseColor":"#1f6878","selectionColor":"#ffffcc","modeMain":"POPUPS","modeAttach":"COLUMNS","modePopup":"POPUPS","layoutAlign":"CENTER","colors":{"theme1":{"value":"#2c6195"},"theme2":{"value":"#aa5060"},"menu":{"value":"#1d4062e6","derived":{"method":"SHADE","color1":"theme1","p1":75,"transp":90}},"tableHead":{"value":"#234d76e6","derived":{"method":"SHADE","color1":"theme1","p1":70,"transp":90}}}}`;
const preferencesCompanyDefault_UAT = `{"darkMode":false,"baseColor":"#1f6878","selectionColor":"#ffffcc","modeMain":"POPUPS","modeAttach":"COLUMNS","modePopup":"POPUPS","layoutAlign":"CENTER","colors":{"theme1":{"value":"#2c6195"},"theme2":{"value":"#aa5060"},"menu":{"value":"#1d4062e6","derived":{"method":"SHADE","color1":"theme1","p1":75,"transp":90}},"tableHead":{"value":"#234d76e6","derived":{"method":"SHADE","color1":"theme1","p1":70,"transp":90}}}}`;
const preferencesCompanyDefault = process.env.REACT_APP_STAGE === 'uat' ? preferencesCompanyDefault_UAT : preferencesCompanyDefault_PROD;
const mltbPx = '6px';

const chakraTheme = {
	initialColorMode: 'light',
	useSystemColorMode: false,
	colors: {
		cardBg: '#09172f',
		//wsysLine: 'transparent',
	},
	fonts: {
		//body: `montserrat`,
	},
	styles: {
		global: {
			'body': {
				//backgroundImage: 'url(/bg3.jpg)',
				backgroundColor: 'var(--chakra-colors-chakra-body-bg)',
				backgroundPosition: 'top',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				backgroundAttachment: 'fixed',
			},

			'table.multi-line tr': { backgroundColor: 'var(--chakra-colors-chakra-body-bg)' },
			'table.multi-line tr td': { borderRight: '1px solid #eee', borderBottom: '1px solid #eee' },
			'table.multi-line tr.first-line': { backgroundColor: '#fff' }, //'#f0f4f6'
			'table.multi-line tr.first-line td': { paddingTop: '0.8em', borderBottom: '1px solid #0005' },
			'table.multi-line tr.selected': { background:'#e6f6ff' },
			'table.multi-line tr.first-line.selected': { ...WSYS_TABLE_SELECTION_STYLE },
			
			'body.ws-mobile-full table.multi-line tr.first-line': {     background: 'linear-gradient(to right, #234d76, #225a91)' },
			'body.ws-mobile-full table.multi-line tr.first-line td': {    background: 'linear-gradient(to right, #234d76, #225a91)', 	color: '#fffe' },
			
			'table.multi-line tr.last-line td': { paddingBottom: '1.0em', borderBottom: '1px solid #0003' },
			'table.multi-line tr.first-line td:first-of-type': { borderTopLeftRadius: mltbPx },
			'table.multi-line tr.first-line td:last-child': { borderTopRightRadius: mltbPx },
			'table.multi-line tr.last-line td:first-of-type': { borderBottomLeftRadius: mltbPx },
			'table.multi-line tr.last-line td:last-child': { borderBottomRightRadius: mltbPx },
			//'table.multi-line tr.separator': { },
			'table.multi-line tr.separator': { height: '8px', overflow: 'hidden' },
			//'table.multi-line tr.last-line td' : { borderBottom: '20px solid #0000004a', marginBottom: '20px'},
			/*'.article h2' :{
				fontSize: '24px',
				fontWeight: 'bold'
			},
			'.article p' :{
				margin: '2em 0',
			},*/
			'.wsys-inverted label': { color: 'primary.400' },
			//'.wsys-inverted input': { textAlign: 'right', fontSize:'xl', borderColor: '#8888' },
			'.wsys-inverted .chakra-input__left-addon': { fontSize:'xl', borderColor: '#8888' },
			'.wsys-inverted .chakra-input__right-addon': { fontSize:'xl', borderColor: '#8888' },

		}
	},
	components: {
		/*Button: {
			variants: {
				wsysHeadIconButton: (props: any) => ({
					...theme.components.Button.variants!.link(props),
					color: '#fff'
				})
			}
		}
		*/

	}
};



root.render(

	<WSysPath>
		<AppTheme icons={APPICONS}
			preferencesCompanyDefault={preferencesCompanyDefault}
			appCustomChakraTheme={chakraTheme}
		>
			<App />
			<ToastProvider />
		</AppTheme>
	</WSysPath>


);
//</React.StrictMode>

serviceWorkerRegistration.register();