import { AlertDialog, Box, Button, FormControl, FormLabel, HStack, Icon, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useBoolean } from "@chakra-ui/react";
import { IMUNKASZAM } from "@coimbra-its/api.konyvelo";
import { CellProps, formatDate, formatNumber, useWSysPage, useWSysPath, WSysGrid, WSysHeaderButton, WSysPage, WSysTable, WSysVert } from "@coimbra-its/websys-ux-core";
import { produce } from "immer";
import { useEffect, useMemo, useRef, useState } from "react";
import { APPICONS } from "../Icons";
import { useKonyveloApi } from "../store/konyvelo.api";
import { MunkaszamList } from "./MunkaszamList";
import { Dok } from "./SzamlaCsatolasok";
import { SzamlaCsatolasUj } from "./SzamlaCsatolasUj";
import { SzamlaDetails } from "./SzamlaDetails";
import { ISZAMLA_TETEL_HERE, SzamlaTetelBont } from "./SzamlaTetelBont";

export function Szamla({ szamlaId, OSZAMLA_IMPORT }: { szamlaId: number, OSZAMLA_IMPORT: string }) {

	const path = useWSysPath();
	const pathDetails = path.useChild('details');



	const api = useKonyveloApi();
	const [data, hub] = api.szamlaIdGet.useData({ id: szamlaId });
	const [munkaszamData] = api.munkaszamGet.useData({ q: '' }, { hub });
	const munkaszamok = munkaszamData?.munkaszamok || [];
	const kedvencMunkaszamok = munkaszamok.filter(msz => msz.KEDVENC);
	const [isEditable, setIsEditable] = useState(false);
	useEffect(() => {
		setIsEditable(OSZAMLA_IMPORT === 'X');
	}, [OSZAMLA_IMPORT]);
	//const tetelek = data?.tetelek || [];

	const csatolasok = data?.csatolasok || [];
	const mszam_bontas = data?.mszam_bontas || [];
	const pathDoc = path.useChildRow('csat', csatolasok, i => i.ID);


	const [MEGJ, setMEGJ] = useState('');
	const [isEdited, setIsEdited] = useState(false);
	const [isReady, setIsReady] = useState(false);
	const szamla = data?.szamla || [];
	const [tetelek, _setTetelek] = useState<ISZAMLA_TETEL_HERE[]>([]);
	const tetelekRef = useRef(tetelek);
	const setTetelek = (t: ISZAMLA_TETEL_HERE[]) => {
		let ready = true;
		const ujTetelek = produce(t, tetelekDraft => {
			for (let tetel of tetelekDraft) {
				let menny = 0;
				let osszeg = 0;
				let ok = true;
				for (let mszamRow of tetel.munkaszamok) {
					menny += mszamRow.MENNY;
					osszeg += mszamRow.OSSZEG;
					if (!mszamRow.munkaszam && (mszamRow.MENNY !== 0 || mszamRow.OSSZEG !== 0)) {
						console.log('mszam nem');
						ok = false;
					}
				}
				if (menny !== tetel.QUANTITY) {
					console.log('menny nem', menny, tetel.QUANTITY);
					ok = false;
				}
				if (osszeg !== tetel.LINENETAMOUNT) {
					console.log('osszeg nem', osszeg, tetel.LINENETAMOUNT);
					ok = false;
				}
				if (tetel.ok !== ok || tetel.mszamOsszeg !== osszeg || tetel.mszamMenny !== menny) {
					console.log(`${tetel.LINENUMBER} changed...`, tetel.ok, ok, tetel.mszamOsszeg, osszeg);
					tetel.ok = ok;
					tetel.mszamMenny = menny;
					tetel.mszamOsszeg = osszeg;
				}
				if (!ok)
					ready = false;
			}
		});
		console.log('ujTetelek', ujTetelek);
		_setTetelek(ujTetelek);
		tetelekRef.current = ujTetelek;
		setIsReady(ready);
	}
	const onTetelMegosztas = (row: ISZAMLA_TETEL_HERE) => {
		setTetelek(produce(tetelekRef.current, tetelek => {
			const xRow = tetelek.find(r => r.ID === row?.ID);
			xRow?.munkaszamok.push({
				munkaszam: undefined, OSSZEG: 0, MENNY: 0,
			});
		}));
		setIsEdited(true);
	}

	// ============================ BETÖLTŐDÉSKOR ==========================
	useEffect(() => {
		setMEGJ(data?.szamlaInfo.MEGJ || '');
	}, [data?.szamlaInfo]);

	useEffect(() => {
		console.log('---------------- itt feltöltés -----------------------');
		setSingleMunkaszam(null);
		setIsSingle(true);
		setIsEdited(false);
		const _tetelek = (data?.tetelek || []).map(tetel => ({
			...tetel,
			mszamMenny: 0,
			mszamOsszeg: 0,
			ok: false,
		}));
		_setTetelek(_tetelek);
		tetelekRef.current = _tetelek;
	}, [data?.tetelek])

	const tetelCount = data?.tetelek.length || 0;



	// ------------------- single munkaszám ----------------
	const [isSingle, setIsSingle] = useState(false);
	const [singleMunkaszam, _setSingleMunkaszam] = useState<IMUNKASZAM | null>(null);
	const setSingleMunkaszam = (mszam: IMUNKASZAM | null) => {
		_setSingleMunkaszam(mszam);
		setTetelek(produce(tetelekRef.current, tetelek => {
			tetelek.forEach(tetel => {
				tetel.munkaszamok = [{
					munkaszam: mszam || undefined,
					MENNY: tetel.QUANTITY,
					OSSZEG: tetel.LINENETAMOUNT,
				}];
			});
		}));
		setIsEdited(true);
	}

	// ----------------------------------------------------------------- MUNKASZÁM KIVÁLASZTÁS -------------------

	const [mszMode, setMszMode] = useState<{ onSelect: (msz: IMUNKASZAM) => void } | undefined>();
	const onMunkaszamMore = () => {
		const onSelect = (msz: IMUNKASZAM) => {
			setMszMode(undefined);
			setOneClickConfirm(msz);

		}
		setMszMode({ onSelect });
	}

	const onTetelenkent = () => {
		setIsEdited(true);
		setIsSingle(false);
	}

	const onTetelSingleMszSelect = (row: ISZAMLA_TETEL_HERE, msz: IMUNKASZAM) => {
		setTetelek(produce(tetelekRef.current, tetelek => {
			const xRow = tetelek.find(r => r.ID === row?.ID)!;
			xRow.munkaszamok = [{
				MENNY: xRow?.QUANTITY, OSSZEG: xRow.LINENETAMOUNT, munkaszam: msz
			}];
		}));
	}

	const onTetelMszMore = (row: ISZAMLA_TETEL_HERE) => {
		const onSelect = (msz: IMUNKASZAM) => {
			setMszMode(undefined);
			onTetelSingleMszSelect(row, msz);
		};
		setMszMode({ onSelect });
	}


	// -------------------------------------------------------------------- CELLS --------------------------------
	const CELLS = useMemo(() => {
		const c: CellProps<ISZAMLA_TETEL_HERE>[] = [
			/*{
				'title': 'Számla tétel', elem: (row) => row.LINEDESCRIPTION, charWidth: 50
			},*/
			{
				'title': 'Számla tétel', elem: (row) => <Box display='flex' flexDir='row' alignItems='center' gap={2}>
					{!isSingle ? (row.ok ? <Icon as={APPICONS.Success} color='green' fontWeight='bold' /> : <Icon as={APPICONS.Alert} color='red' />) : ''}
					{row.LINEDESCRIPTION}
				</Box>, charWidth: 50
			},
			{ 'title': 'Menny.', elem: (row) => formatNumber(row.QUANTITY, 0) + ' ' + (row.UNITOFMEASUREOWN || row.UNITOFMEASURE), charWidth: 10, align: 'right' },
			//{ 'title': 'Bruttó', elem: (row) => formatNumber(row.LINENETAMOUNT + row.LINEVATAMOUNT, 0) + ' ' + (data?.szamla.CURRENCY === 'HUF' ? 'Ft' : data?.szamla.CURRENCY), charWidth: 10, align: 'right' },
			{ 'title': 'Nettó', elem: (row) => <strong>{formatNumber(row.LINENETAMOUNT, 0) + ' ' + (data?.szamla.CURRENCY === 'HUF' ? 'Ft' : data?.szamla.CURRENCY)}</strong>, charWidth: 10, align: 'right' },

		];
		if (isSingle) {
			c.splice(2,0,
				{ 'title': 'Egységár', elem: (row) => formatNumber(row.UNITPRICE, 0) + ' ' + (data?.szamla.CURRENCY === 'HUF' ? 'Ft' : data?.szamla.CURRENCY), charWidth: 10, align: 'right' },
			);
		} else {
			c.push({
				'title': 'Mszám', cardNoCaption: true, charWidth: 80, elem: (row) => <>


					{row.munkaszamok.length === 1
						? <Box className='bontas-container' display='flex' flexDir='row' gap={2} flexWrap='wrap' width='100%'>
							{(!row.munkaszamok[0].munkaszam || kedvencMunkaszamok.find(m => row.munkaszamok[0].munkaszam?.MSZAM === m.MSZAM))
								? kedvencMunkaszamok.map((msz, mix) => <Button key={msz.MSZAM} colorScheme='primary' size='xs'
									variant={row.munkaszamok[0].munkaszam === msz ? 'solid' : 'outline'}
									onClick={() => onTetelSingleMszSelect(row, msz)}
								>
									{msz.TARGY}
								</Button>)
								: <Button colorScheme='primary' size='xs' variant='solid'>{row.munkaszamok[0].munkaszam.TARGY}</Button>}
							<Button size='xs' colorScheme='blue' variant='outline' onClick={() => onTetelMszMore(row)} >További...&nbsp; <Icon as={APPICONS.Right} /></Button>
							<Button size='xs' colorScheme='blue' variant='outline' onClick={() => onTetelMegosztas(row)}>Megbontom&nbsp;<Icon as={APPICONS.Share} /></Button>
						</Box>
						: <SzamlaTetelBont row={row} munkaszamok={munkaszamok} kedvencMunkaszamok={kedvencMunkaszamok} setTetelek={setTetelek} tetelekRef={tetelekRef} CURRENCY={data?.szamla.CURRENCY} setIsEdited={setIsEditable} />
					}
				</>

			})

		}
		return c;
	}, [data?.szamla.CURRENCY, munkaszamok, kedvencMunkaszamok, tetelCount, isEdited, isSingle]);

	// ----------------------- save ---------------------
	const szamlaIdPost = api.szamlaIdPost.useAction();
	const doSave = () => {
		hub.tryCatch(async () => {
			await szamlaIdPost({ id: szamlaId, tetelek, szamlaInfo: { ...data.szamlaInfo, ...{ MEGJ } } });
		});
	}
	const page = useWSysPage({ title: data?.szamla.SUPPLIERNAME || 'Számla', maxCols: 4, isEdited, wsStyle: 'inverted' /*, ...tools.forPage*/ });


	const [oneClickConfirm, setOneClickConfirm] = useState<IMUNKASZAM | undefined>(undefined);



	const oneClickSave2 = () => {
		setOneClickConfirm(undefined);
		const oneTetelek: ISZAMLA_TETEL_HERE[] = [];
		data.tetelek.forEach(orig => {
			oneTetelek.push({
				...orig,
				mszamMenny: orig.QUANTITY,
				mszamOsszeg: orig.LINENETAMOUNT,
				ok: true,
				munkaszamok: [{
					MENNY: orig.QUANTITY,
					OSSZEG: orig.LINENETAMOUNT,
					munkaszam: oneClickConfirm,
				}]
			})
		})

		hub.tryCatch(async () => {
			await szamlaIdPost({ id: szamlaId, tetelek: oneTetelek, szamlaInfo: { ...data.szamlaInfo, ...{ MEGJ: '' } } });
		});
	}

	const onCancel = () => {
		setIsEdited(false);
		setIsSingle(true);
	}


	return <WSysPage page={page} hubs={[hub]} /*tools={tools.tools(page)}*/ fullScroll={3}
		//headerTitle={data?.szamla.SUPPLIERNAME + ' / ' + data?.szamla.INVOICENUMBER}
		tools={<>{OSZAMLA_IMPORT === 'X' ?
			<HStack>
				{false && !isEdited && <WSysHeaderButton icon={<APPICONS.Edit />} colorScheme="primary" variant='outline' onClick={() => setIsEdited(true)}  >Szerkeszt</WSysHeaderButton>}
				{isEdited && <WSysHeaderButton icon={<APPICONS.Cancel />} onClick={onCancel}  >Mégsem</WSysHeaderButton>}
				{isEdited && <WSysHeaderButton icon={<APPICONS.Save />} colorScheme="primary" isDisabled={!isReady} onClick={() => doSave()} >Mentés</WSysHeaderButton>}
			</HStack>
			: <>
				{OSZAMLA_IMPORT === 'N' && 'Kézzel rögzítve'}
				{/*OSZAMLA_IMPORT === 'R' && <SzamlaCsatolasUj hub={hub} DOKID={data?.szamlaInfo.DOKID!} />*/}
				{/*OSZAMLA_IMPORT === 'T' && 'Kész'*/}
			</>
		}{!isEdited && <WSysHeaderButton onClick={() => pathDetails.open()} icon={<APPICONS.Details />}
		>Részletek</WSysHeaderButton>}

		</>

		}
	>
		<WSysVert mode="static" p={3} isInverted={true}>
			<WSysGrid minCols={3} gap={9} p={3} cols={[2, 3]}>
				<Stack gap={0}>

					<FormControl>
						<FormLabel color='primary.400'>Számla száma</FormLabel>
						<Input value={data?.szamla.INVOICENUMBER} onChange={() => { }} readOnly={true}
							textAlign='right' variant='flushed' fontSize='xl'
						/>
					</FormControl>

					<FormControl>
						<FormLabel color='primary.400'>Számla dátuma</FormLabel>
						<Input value={formatDate(data?.szamla.INVOICEISSUEDATE)} onChange={() => { }} readOnly={true}
							textAlign='right' variant='flushed' fontSize='xl'
						/>
					</FormControl>

					<FormControl>
						<FormLabel color='primary.400'>Bruttó összeg</FormLabel>
						<InputGroup
						>
							<Input value={formatNumber(szamla.INVOICENETAMOUNT + szamla.INVOICEVATAMOUNT, szamla.CURRENCY === 'HUF' ? 0 : 2)} onChange={() => { }} readOnly={true}
								textAlign='right' variant='flushed' fontSize='xl'
							/>
							<InputRightElement fontSize='xl' >&nbsp;{(szamla.CURRENCY === 'HUF' ? 'Ft' : szamla.CURRENCY)}</InputRightElement>
						</InputGroup>
					</FormControl>

					<FormControl>
						<FormLabel color='primary.400'>Nettó összeg</FormLabel>
						<InputGroup
						>
							<Input value={formatNumber(szamla.INVOICENETAMOUNT, szamla.CURRENCY === 'HUF' ? 0 : 2)} onChange={() => { }} readOnly={true}
								textAlign='right' variant='flushed' fontSize='3xl'
							/>
							<InputRightElement fontSize='xl' >&nbsp;{(szamla.CURRENCY === 'HUF' ? 'Ft' : szamla.CURRENCY)}</InputRightElement>
						</InputGroup>
					</FormControl>

				</Stack>
				<Box>

					{!isEdited && isEditable && mszam_bontas.length === 0 && munkaszamData && <Stack my={4} mx={0} >
						{kedvencMunkaszamok.map(msz => <Button key={msz.MSZAM} colorScheme='primary' onClick={() => setOneClickConfirm(msz)} >

							{msz.TARGY}
						</Button>)}

						<Button colorScheme='blue' variant='solid' onClick={onMunkaszamMore} >További kategóriák&nbsp;<Icon as={APPICONS.Right} /></Button>
						<Button colorScheme='blue' variant='solid' onClick={onTetelenkent}  >Számla tételenként adom meg&nbsp;<Icon as={APPICONS.Edit} /></Button>

					</Stack>
					}

					{mszam_bontas.length > 0 && <Stack my={4} mx={0} p={2} borderRadius='md'
						bg='var(--chakra-colors-primary-600)'
					//color='var(--chakra-colors-gray-800)'
					>
						{mszam_bontas.map((row, ix) => <Box key={ix}
							display='flex' justifyContent='space-between'>
							<Box flex='1'>{row.TARGY}</Box>
							<Box flex='0 0 10em' fontWeight='bold' textAlign='right'>{formatNumber(row.OSSZEG, 0)}&nbsp;Ft</Box>
						</Box>)}

					</Stack>}
					{mszam_bontas.length < 0 && <Stack my={4} mx={0}>
						{mszam_bontas.map((row, ix) => <Button key={ix} colorScheme='primary' variant='outline' >
							{row.TARGY}
						</Button>)}

					</Stack>}

					{(data?.szamlaInfo?.DOKID || 0) > 0 && <Stack><SzamlaCsatolasUj hub={hub} DOKID={data?.szamlaInfo.DOKID!} /></Stack>}

				</Box>
			</WSysGrid>






		</WSysVert>

		{
			csatolasok.length > 0 && <WSysVert mode='static'>
				<Box bg='menuBg' color='#fffd' fontWeight={600} px={3} >Számlakép</Box>
				<WSysGrid gap={2} p={2}
					bg='blue.800'
					{...page.propsOut.widthCols > 2 ? {
						display: 'flex', flexDir: 'row', flexWrap: 'wrap'
					} : {
						display: 'flex', flexDir: 'column', alignItems: 'stretch'
					}}

				>
					{csatolasok.map(cs => <Button
						textOverflow='ellipsis' overflow='hidden' justifyContent='flex-start' minWidth={0}
						onClick={() => pathDoc.open('csat/' + cs.ID)}
						variant='solid'
						{...pathDoc.pathSelectedId === '' + cs.ID && {
							colorScheme: 'blue'
						}}
					><Icon as={APPICONS.Photo} />&nbsp;{cs.FILENEV}</Button>)}
				</WSysGrid>
			</WSysVert>
		}


		<WSysVert mode="rest">
			<WSysTable mode={isSingle ? 3 : "MULTILINE-TABLE"}
				noHead={true}
				title={<Box bg='menuBg' color='#fffd' fontWeight={600} px={3} >Tételek</Box>}
				cells={CELLS}
				rows={tetelek}
			/>
		</WSysVert>

		{data && pathDetails.on(<SzamlaDetails szamla={szamla} szamlaInfo={data?.szamlaInfo!} />)}
		{pathDoc.on(pathDoc.forTable.selectedRow && <Dok csatolas={pathDoc.forTable.selectedRow} />)}
		{munkaszamok && mszMode && <MunkaszamList munkaszamok={munkaszamok} onSelect={mszMode.onSelect} onClosed={() => setMszMode(undefined)} />}

		<Modal isOpen={!!oneClickConfirm} onClose={() => setOneClickConfirm(undefined)}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Felírás</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					Az egész számla a <Box as='span' fontWeight='bold'>"{oneClickConfirm ? oneClickConfirm.TARGY : '???'}"</Box> munkaszámra kerül?
				</ModalBody>

				<ModalFooter>
					<Button mr={3} onClick={() => setOneClickConfirm(undefined)}><Icon as={APPICONS.Cancel} />&nbsp;Mégsem</Button>
					<Button colorScheme='primary' mr={3} onClick={() => oneClickSave2()}><Icon as={APPICONS.Save} />&nbsp;Mentés</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>


	</WSysPage >
}