import { WSysLayout } from '@coimbra-its/websys-ux-core';
import { AppHub, WebSysNav } from './sys/NavBar';

import { Box, Button, Img } from '@chakra-ui/react';
import { useWSysAuthSlice } from './store/authSlice';
import SignInForm from './sys/SignInForm';
import { SignOut } from './sys/SignOut';
import { WSysServerMessages } from './temp/serverMessage';

console.log('STARTED WITH API:', process.env.REACT_APP_API_BASE_URL);

function App() {
	const authSlice = useWSysAuthSlice();
	const user = authSlice.user;



	return (<>
		{!user
			? <WSysLayout>
				<SignInForm />
			</WSysLayout>
			: <WSysLayout nav={<WebSysNav />}>
				<AppHub />
				<SignOut />
			</WSysLayout>
		}
		<WSysServerMessages />
	</>);
}

export default App;
