import { KONYVELO_ROUTES } from "@coimbra-its/api.konyvelo";
import { WSysApi } from "@coimbra-its/websys-ux-core";
import { createContext, useContext } from "react";
import { useSessionId } from "./authSlice";

export const KonyveloApi = createContext(new WSysApi(process.env.REACT_APP_API_BASE_URL + '' || '', KONYVELO_ROUTES,
    headers => {
        const sessionId = useSessionId();
        (headers as any)['authorization'] = `Bearer ${sessionId}`;
    }
));


export function useKonyveloApi() {
    const api = useContext(KonyveloApi);
    return api.ep;
}



