import { Box, Button, Card, CardBody, Heading, Icon, LightMode, Link, Stack, Text } from "@chakra-ui/react";
import { WSysGrid, WSysHeaderButton, WSysPage, useWSysLayoutContext, useWSysPage, useWSysPath } from "@coimbra-its/websys-ux-core";
import { SunMainPage } from "../components/Sun";
import { APPICONS } from "../Icons";
import { useWSysAuthSlice } from "../store/authSlice";
import { useKonyveloApi } from "../store/konyvelo.api";
import { SzamlaLista } from "./SzamlaLista";



export function Dashboard() {

	const api = useKonyveloApi();
	//const [data, hub] = api.dashboardGet.useData({});



	const authSlice = useWSysAuthSlice();
	const user = useWSysAuthSlice(s => s.user);

	const path = useWSysPath();
	const page = useWSysPage({ title: 'WebSys Könyvelő', maxCols: 3, onClosed: false });
	const layoutCtx = useWSysLayoutContext();




	return <WSysPage page={page}
		tools={layoutCtx.isMobileFull && <WSysHeaderButton icon={<APPICONS.SignOut />}
			onClick={() => { authSlice.setShowSignOut(true) }} >Kilépés</WSysHeaderButton>}
	>
		<Stack px={4} py={8} justifyContent='flex-start' h='100%'>
			<Button onClick={() => path.open('szamlak')} colorScheme="primary" ><Icon as={APPICONS.Szamlak} />&nbsp;Számlák</Button>
			<Button onClick={() => path.open('szamlak')} colorScheme="primary" ><Icon as={APPICONS.Chart} />&nbsp;Kimutatás</Button>
		</Stack>

	</WSysPage>
}
