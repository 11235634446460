import { Box, Select } from "@chakra-ui/react";
import { IOSZAMLA_BESZLA_FEJ_PUB, TSzamlaFilter } from "@coimbra-its/api.konyvelo";
import { CellProps, formatDate, formatNumber, useWSysLayoutContext, useWSysPage, useWSysPath, WSysPage, WSysSearch, WSysTable, WSysVert } from "@coimbra-its/websys-ux-core";
import { useKonyveloApi } from "../store/konyvelo.api";
import { Szamla } from "./Szamla";

//const CELLS = new TableMeta(OSZAMLA_BESZLA_FEJ_META).forTable();

export const CELLS: CellProps<IOSZAMLA_BESZLA_FEJ_PUB>[] = [
	{ 'title': 'Szállító', elem: (row) => row.SUPPLIERNAME, charWidth: 40 },
	{ 'title': 'Számlaszám', elem: (row) => row.INVOICENUMBER, charWidth: 20 },
	//{ 'title': 'Adószám', elem: (row) => row.SUPPLIERTAXNUMBER, charWidth: 11 },
	{ 'title': 'Dátum', elem: (row) => formatDate(row.INVOICEISSUEDATE), charWidth: 11 },
	{ 'title': 'Bruttó', elem: (row) => formatNumber(row.INVOICENETAMOUNT + row.INVOICEVATAMOUNT, 0) + ' ' + (row.CURRENCY === 'HUF' ? 'Ft' : row.CURRENCY), charWidth: 11, align: 'right' },
	{
		'title': 'Státusz', elem: (row) => {
			switch (row.OSZAMLA_IMPORT) {
				case 'T': return 'Kész';
				case 'N': return 'Kézzel rögzítve';
				case 'R': return row.KEPEK > 0 ? 'Számlakép rögzítve' : 'Nincs számlakép';
				default: return 'Besorolásra vár';
			}
		}, charWidth: 20
	},
];


export function SzamlaLista() {


	const path = useWSysPath();
	const [q] = path.useParam('q');
	const [statusz, setStatusz] = path.useParamValue<TSzamlaFilter>('status', 'nincskesz', val => val, val => val as TSzamlaFilter);

	const api = useKonyveloApi();
	const [szamlaGetRes, hub] = api.szamlaGet.useData({ statusz, q });
	const szamlak = szamlaGetRes?.szamlak || [];

	const szamlaPath = path.useChildRow('', szamlak, szamla => szamla.ID);
	const layout = useWSysLayoutContext();
	const page = useWSysPage({ title: 'Számlák', maxCols: szamlaPath.isOpen ? (Math.max(layout.widthCols - 4, 2)) : 10 });


	return <WSysPage page={page} hubs={[hub]}

		tools={<Box display='flex' gap={2}>
			<WSysSearch name='q' />
			<Select size='sm' value={statusz} onChange={e => setStatusz(e.currentTarget.value as TSzamlaFilter)}>
				<option value='nincskesz' >Új</option>
				<option value='mind'>Mind</option>
			</Select>
		</Box>}

	>
		<WSysVert mode="rest">
			<WSysTable
				cells={CELLS}
				rows={szamlak}
				{...szamlaPath.forTable}
				mode={3}
				noHead={layout.isMobileFull}
			/>
		</WSysVert>

		{szamlaPath.on(szamlaPath.forTable.selectedRow && <Szamla szamlaId={szamlaPath.forTable.selectedRow.ID} OSZAMLA_IMPORT={szamlaPath.forTable.selectedRow.OSZAMLA_IMPORT || 'X'} />)}
	</WSysPage>
}