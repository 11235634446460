import { Box, Button, FormControl, HStack, Icon, IconButton, Input, InputGroup, InputRightAddon, InputRightElement, Stack } from "@chakra-ui/react";
import { IMUNKASZAM, ISZAMLA_TETEL_FORM } from "@coimbra-its/api.konyvelo";
import { formatNumber, useWSysLayoutContext, useWSysPage, WSysPage, WSysStickyBox, WSysVert } from "@coimbra-its/websys-ux-core";
import React, { MutableRefObject, PropsWithChildren, useState } from "react";
import { MunkaszamSelect } from "../components/MunkaszamSelect";
import { APPICONS } from "../Icons";
import { NumberInput } from "../temp/NumberInput";
import { produce } from "immer";
import { MunkaszamList } from "./MunkaszamList";

export interface IMSZAM_ROW {
    MENNY: number,
    OSSZEG: number,
    munkaszam?: IMUNKASZAM
}

export interface ISZAMLA_TETEL_HERE extends ISZAMLA_TETEL_FORM {
    munkaszamok: IMSZAM_ROW[],
    ok: boolean,
    mszamMenny: number,
    mszamOsszeg: number,
    //CURRENCY: string;
}


export interface SzamlaTetelBontProps {
    row: ISZAMLA_TETEL_HERE;
    munkaszamok: IMUNKASZAM[];
    kedvencMunkaszamok: IMUNKASZAM[];
    tetelekRef: MutableRefObject<ISZAMLA_TETEL_HERE[]>;
    setTetelek: (t: ISZAMLA_TETEL_HERE[]) => void;
    setIsEdited: (value: React.SetStateAction<boolean>) => void
    CURRENCY: string;
}

export function SzamlaTetelBont({ row, munkaszamok, kedvencMunkaszamok, tetelekRef, setTetelek, setIsEdited, CURRENCY }: SzamlaTetelBontProps) {
    const [open, setOpen] = useState(true);
    const [mszMode, setMszMode] = useState<{ onSelect: (msz: IMUNKASZAM) => void } | undefined>();

    const onMunkaszamSelected = (row: ISZAMLA_TETEL_HERE, mszamRowIx: number, munkaszam: IMUNKASZAM | null) => {
        setTetelek(produce(tetelekRef.current, tetelek => {
            const xRow = tetelek.find(r => r.ID === row?.ID);
            xRow!.munkaszamok[mszamRowIx].munkaszam = munkaszam || undefined;
            //xRow!.munkaszamok[mszamRowIx].MSZAM = munkaszam?.MSZAM;
        }));

        setIsEdited(true);
    }

    const onMennyChange = (row: ISZAMLA_TETEL_HERE, mszamRowIx: number, menny: number) => {
        console.log('MENNY CHANGE', menny);
        setTetelek(produce(tetelekRef.current, tetelek => {
            const xRow = tetelek.find(r => r.ID === row?.ID);
            xRow!.munkaszamok[mszamRowIx].MENNY = menny;
            xRow!.munkaszamok[mszamRowIx].OSSZEG = menny * row.UNITPRICE;
        }));
        setIsEdited(true);
    }

    const onOsszegChange = (row: ISZAMLA_TETEL_HERE, mszamRowIx: number, osszeg: number) => {
        setTetelek(produce(tetelekRef.current, tetelek => {
            const xRow = tetelek.find(r => r.ID === row?.ID);
            xRow!.munkaszamok[mszamRowIx].OSSZEG = osszeg;
            xRow!.munkaszamok[mszamRowIx].MENNY = Math.round(osszeg / row.UNITPRICE * 100) / 100;
        }));
        setIsEdited(true);
    }

    const onRowAdd = (row: ISZAMLA_TETEL_HERE) => {
        setTetelek(produce(tetelekRef.current, tetelek => {
            const xRow = tetelek.find(r => r.ID === row?.ID);
            xRow?.munkaszamok.push({
                munkaszam: undefined, OSSZEG: 0, MENNY: 0,
            });
        }));
        setIsEdited(true);
    }

    const onRowDelete = (row: ISZAMLA_TETEL_HERE, mszamRowIx: number) => {
        setTetelek(produce(tetelekRef.current, tetelek => {
            const xRow = tetelek.find(r => r.ID === row?.ID);
            xRow!.munkaszamok = xRow!.munkaszamok.filter((m, mix) => mix !== mszamRowIx);
        }));
        setIsEdited(true);
    }

    const onTetelMszMore = (row: ISZAMLA_TETEL_HERE, mszamRowIx: number) => {
        const onSelect = (msz: IMUNKASZAM) => {
            setMszMode(undefined);
            onMunkaszamSelected(row, mszamRowIx, msz);
        };
        setMszMode({ onSelect });
    }


    const layout = useWSysLayoutContext();
    const openable = layout.isMobileFull;

    // ------------------------------------------------ PARTS ------------------------------------------------------------
    let flex10 = openable ? {} : { flex: '0 0 10em' };
    let flex6 = openable ? {} : { flex: '0 0 6em' };

    const mszamKivall = (mszamRow: IMSZAM_ROW, mszamRowIx: number) => <>{(!mszamRow.munkaszam || kedvencMunkaszamok.find(m => mszamRow.munkaszam?.MSZAM === m.MSZAM))
        ? kedvencMunkaszamok.map(msz => <Button key={msz.MSZAM} colorScheme='primary' size='sm' overflow='hidden' 
            variant={mszamRow.munkaszam === msz ? 'solid' : 'outline'}
            onClick={() => onMunkaszamSelected(row, mszamRowIx, msz)}
        >{msz.TARGY}</Button>)
        : <Button colorScheme='primary' size='sm' variant='solid' overflow='hidden' >{mszamRow.munkaszam.TARGY}</Button>}

        <Button size='xs' colorScheme='blue' variant='outline' onClick={() => onTetelMszMore(row, mszamRowIx)} >További...&nbsp; <Icon as={APPICONS.Right} /></Button></>


    let mennyInput = (mszamRow: IMSZAM_ROW, mszamRowIx: number) => <FormControl {...flex10} >
        <InputGroup size='sm' variant='outline'>
            <NumberInput  value={mszamRow.MENNY} textAlign='right' bg='transparent' borderRadius='none'
                onDebouncedChange={e => onMennyChange(row, mszamRowIx, e)}
            />
            <InputRightAddon px={1}>{(row.UNITOFMEASUREOWN || row.UNITOFMEASURE)}</InputRightAddon>
        </InputGroup>
    </FormControl>;

    let osszegInput = (mszamRow: IMSZAM_ROW, mszamRowIx: number) => <FormControl {...flex10} >
        <InputGroup size='sm' variant='outline'>
            <NumberInput  value={mszamRow.OSSZEG} textAlign='right' bg='transparent' borderRadius='none'
                onDebouncedChange={e => onOsszegChange(row, mszamRowIx, e)}
            />
            <InputRightAddon px={1}>{CURRENCY}</InputRightAddon>
        </InputGroup>
    </FormControl>;





    return <>
        {openable
            ? <> {/* -------------------------------------- INLINE NEM SZERKESZTHETŐ ----------------------------------- */}
                <Box display='flex' flexDir='row' alignItems='stretch' overflow='hidden'>
                    <Box flex={1} pr={2} overflow='hidden'>
                        {row.munkaszamok.map((mszamRow, mix) => <Box key={mix} className='bontas-row' width='100%' py={1}
                            display='flex' flexDir='row' justifyContent='stretch' >
                            <Button colorScheme='primary' variant='solid' size='xs'
                                flex='1' display='flex' flexDir='row' alignItems='center' justifyContent='space-between'
                            >
                                <Box as='span' flex='1' overflow='hidden' textOverflow='ellipsis'>{mszamRow.munkaszam?.TARGY}</Box>
                                <Box as='span' fontWeight='bold' >{formatNumber(mszamRow.OSSZEG, 0)}&nbsp;{CURRENCY}</Box>

                            </Button>
                        </Box>)}
                    </Box>
                    <Box flex='0 0 auto' >
                        <IconButton height='100%' aria-label="Bontás szerkesztése" icon={<APPICONS.Edit />} colorScheme="blue" variant='outline' onClick={() => setOpen(!open)} size='sm' />
                    </Box>

                </Box>
                {open && <SzamlaTetelBontPage title={row.LINEDESCRIPTION || 'Bontás'} onClosed={() => setOpen(false)} >
                    {/* -------------------------------------- MEGNYÍLÓ ABLAKBAN SZERKESZTÉS ----------------------------------- */}
                    <WSysStickyBox bg='#c6d3df'
                        footer={
                            <Box className='bontas-row' display='flex' flexDir='column' alignItems='center' justifyContent='center' p={1}
                                bg='var(--chakra-colors-chakra-body-bg)' borderTop='2px solid #000'>
                                {row.ok ? <Button variant='solid' colorScheme="blue" onClick={()=>setOpen(false)}>Kész</Button> : <>

                                    <Box>
                                        Tétel:&nbsp;<Box as='span' fontWeight='bold'>{formatNumber(row.QUANTITY, 0)}&nbsp;{(row.UNITOFMEASUREOWN || row.UNITOFMEASURE)}</Box>
                                        &nbsp;/&nbsp;<Box as='span' fontWeight='bold'>{formatNumber(row.LINENETAMOUNT, 0)}&nbsp;{CURRENCY}</Box>
                                    </Box>
                                    <Box color='red'>
                                        <Icon as={APPICONS.Alert} />&nbsp; Bontás:
                                        &nbsp;<Box as='span' fontWeight='bold'>{formatNumber(row.mszamMenny, 0)}&nbsp;{(row.UNITOFMEASUREOWN || row.UNITOFMEASURE)}</Box>
                                        &nbsp;/&nbsp;<Box as='span' fontWeight='bold'>{formatNumber(row.mszamOsszeg, 0)}&nbsp;{CURRENCY}</Box>
                                    </Box>
                                </>}
                            </Box>
                        }
                    >
                        <Stack p={1} >
                            {row.munkaszamok.map((mszamRow, mszamRowIx) => <Box key={mszamRowIx} className='bontas-row'
                                display='flex' flexDir='column' mb={1} bg='var(--chakra-colors-chakra-body-bg)' borderRadius='10px' p={2} gap={2} boxShadow='md'
                            >
                                {mszamKivall(mszamRow, mszamRowIx)}
                                {mennyInput(mszamRow, mszamRowIx)}
                                {osszegInput(mszamRow, mszamRowIx)}
                                <Button aria-label="delete" variant='outline' size='xs' onClick={() => onRowDelete(row, mszamRowIx)} ><Icon as={APPICONS.DeleteRow} />&nbsp;Sor törlése</Button>

                            </Box>)}
                            <Button aria-label="add" variant='solid' onClick={() => onRowAdd(row)} ><Icon as={APPICONS.Create} />&nbsp;Még egy sor</Button>
                        </Stack>
                    </WSysStickyBox>
                </SzamlaTetelBontPage>}
            </>
            : <> {/* -------------------------------------- INLINE SZERKESZTEM ----------------------------------- */}
                {row.munkaszamok.map((mszamRow, mszamRowIx) => <Box key={mszamRowIx} className='bontas-row'
                    display='flex' flexDir='row' gap={1} width='100%' >
                    <HStack flex={1} overflow='hidden'>
                        {mszamKivall(mszamRow, mszamRowIx)}
                    </HStack>
                    {mennyInput(mszamRow, mszamRowIx)}
                    {osszegInput(mszamRow, mszamRowIx)}
                    <Box {...flex6} display='flex' flexDir='row' justifyContent='flex-start'>
                        <IconButton aria-label="delete" icon={<APPICONS.Delete />} variant='ghost' onClick={() => onRowDelete(row, mszamRowIx)} >x</IconButton>
                        {mszamRowIx === row.munkaszamok.length - 1 && <IconButton aria-label="add" icon={<APPICONS.NewRow />} variant='ghost' onClick={() => onRowAdd(row)} />}
                    </Box>
                </Box>)}
                {!row.ok && <Box className='bontas-row' width='100%' display='flex' flexDir='row' color='red' alignItems='center'>
                    <Box flex='1' textAlign='right'>Bontás összesen:</Box>
                    <Box {...flex10} textAlign='right' ><Box padding='6px 1em' fontSize='lg' fontWeight='bold' >{formatNumber(row.mszamMenny, 0)}&nbsp;{(row.UNITOFMEASUREOWN || row.UNITOFMEASURE)}</Box></Box>
                    <Box {...flex10} textAlign='right' ><Box padding='6px 1em' fontSize='lg' fontWeight='bold' >{formatNumber(row.mszamOsszeg, 0)}&nbsp;{CURRENCY}</Box></Box>
                    <Box {...flex6} ><Icon padding='4px 1em' as={APPICONS.Alert} /></Box>
                </Box>}
            </>
        }
        {munkaszamok && mszMode && <MunkaszamList munkaszamok={munkaszamok} onSelect={mszMode.onSelect} onClosed={() => setMszMode(undefined)} />}
    </>
}

export interface SzamlaTetelBontPageProps {
    title: string;
    onClosed: () => void;
}
export function SzamlaTetelBontPage({ children, onClosed, title }: PropsWithChildren<SzamlaTetelBontPageProps>) {
    const page = useWSysPage({ title, maxCols: 3, variant: 'attach', onClosed, wsStyle: 'inverted' });
    return <WSysPage page={page} >

        {children}
    </WSysPage>

}